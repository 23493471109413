<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
tr {
  /* display: block; */
  cursor: pointer;
  /* background-color: blue; */
}
th {
  border-bottom: 1px solid #343a40;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.device {
  color: #8db4e588;
}
</style>
<template>
  <table class="table table-hover inshadow ">
    <thead class="bg-dark">
      <tr class="prevent-select">
        <th @click="toggleOrderbyName()">
          Nombre
          <Icon v-if="orderBy=='name' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='name' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <th @click="toggleOrderbyLastActivity()">
          Visto
          <Icon v-if="orderBy=='last_activity' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='last_activity' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <!-- <th style="width: 40px">
          <svg data-v-4ef421ca="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512" class="text-warning"><path data-v-4ef421ca="" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" fill="currentColor" /></svg>
        </th> -->
      </tr>
    </thead>
    <tbody>
      <tr v-for="it in devices" :key="it._id" @click="onItemClick(it,...arguments)">
        <!-- <td>1.</td> -->
        <td>
          <p class="mb-0">
            <Icon
              icon="ph:computer-tower-fill"
              height="1.7em"
              class="mr-1 fsicon device hover-lighter"
              :class="{'text-primary': it.p_isActive, 'text-muted': !it.tinchost}"
            />
            <span class="text-primary">
              <strong>
                {{ it.tinchost }}
              </strong>
            </span>
            <span>
              {{ it.name }}
            </span>
            <span>
              <svg
                v-show="it.users.length>1" class="text-warning" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512"
              >
                <path
                  d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </p>
          <span class="text-info small">
            {{ it.tincip }}
            <span :class="{'text-warning': !it.place_name}">
              {{ it.place_name ?? "Sin lugar asignado" }}
            </span>
          </span>
        </td>
        <td class="text-nowrap" style="width: 1%;">
          {{ it.p_lastActivity }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { format, parseISO, differenceInSeconds } from "date-fns";
import esmx from "date-fns/locale/es";
import { Icon } from "@iconify/vue2";

import ServiceApi from "./Devices.service";

export default {
  components: {
    Icon
  },
  props: {
    apiurl: {
      type: String,
      default: "/api/devices"
    }
  },
  data() {
    return {
      devices: [],
      orderBy: "last_activity",
      orderAsc: false
    };
  },
  computed: {
    isOpen() {
      const { status = "open" } = this.item;
      return status === "open";
    },
  },

  watch: {
    apiurl: {
      immediate: true,
      handler(val, old) {
        console.log("Reload devices", old, val);
        this.fillInitial();
      }
    }
  },
  methods: {
    toggleOrderbyName() {
      if (this.orderBy === "name") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "name";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbyLastActivity() {
      if (this.orderBy === "last_activity") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "last_activity";
      this.orderAsc  = true;
      this.fillInitial();
    },
    onItemClick(it) {
      this.$emit("device-clicked", it);
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      // const { id:cid = null } = this.$route.params;
      // if (!cid) {
      //   console.log("Refusing to refill empty route");
      //   return;
      // }
      ServiceApi.find(this.apiurl, { per_page:100, sort:`${this.orderBy}|${this.orderAsc ? "asc" : "desc"}` }).then(res => {
        if (!res) {
          console.log("Got empty");
          this.item = {};
          return;
        }
        console.log("Got", res);

        const parsedRows = res.rows.map(it => {
          const { last_activity: lastact = null, users = [] } = it;
          if (!lastact) {
            return {
              ...it, users, p_lastActivity:"-", p_isActive: false, p_secsSince: 310
            };
          }
          const dateOrig = parseISO(lastact);
          const datetime = format(dateOrig, "dd MMM, yy HH:mm", { locale: esmx });
          const secs = Math.abs(differenceInSeconds(dateOrig, new Date()));
          return {
            ...it,
            users,
            p_isActive: secs < 310,
            p_secsSince: secs,
            p_lastActivity:`${datetime}`
          };
        });
        // this.devices = res.rows;
        // console.log("Parsed", parsedRows);
        this.devices = parsedRows;
      });
    },
    refresh() {
      this.fillInitial();
    }
  }
};
</script>
